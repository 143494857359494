import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PrimaryButton from "../Button/PrimaryButton";

export default function CustomCard({ title, children, className, backable = true, buttonTitle, linkTo, extraClassName, ...props }) {
    const history = useHistory();
    return (
        <div className={`label-table ${className}`}>
            {backable ? (
                <div className="surface-300 py-3 px-3 flex justify-content-between w-full m-0">
                    <div className="flex justify-content-start align-items-center">
                        <i className="pi pi-angle-left text-4xl my-auto cursor-pointer" onClick={() => history.goBack()} />
                        <div className="text-2xl my-auto">{title}</div>
                    </div>
                    {buttonTitle ? (
                        <div>
                            <PrimaryButton
                                severity="secondary"
                                className="mt-2 md:mt-0"
                                onClick={() => history.push(linkTo)}
                                label={buttonTitle}
                                extraClassName={extraClassName}
                            />
                        </div>
                    ) : null}
                </div>
            ) : null}
            <div className="p-2  border border-1 border-300 p-fluid">{children}</div>
        </div>
    );
}
