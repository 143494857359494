import React from "react";
import CustomCard from "../../shared/Card/CustomCard";
import CompanyForm from "./CompanyForm";

export default function EditCompany({ name }) {
    return (
        <CustomCard title={name}>
            <CompanyForm type="EDIT" />
        </CustomCard>
    );
}
