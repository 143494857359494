import React from "react";
import ClubsForm from "./ClubsForm";
import CustomCard from "../../../shared/Card/CustomCard";

const AddClubs = ({ name }) => {
    return (
        <CustomCard title={name}>
            <ClubsForm type="ADD" />
        </CustomCard>
    );
};

export default AddClubs;
