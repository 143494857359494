import api from "../../services/api";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";

const addOwners = (companyId, data, setLoading, history) => async (dispatch) => {
    setLoading(true);

    const payload = {
        ...data,
        company: companyId,
    };

    const res = await api("post", Constants.END_POINT.OWNERS, payload);
    if (res.success) {
        history.goBack();
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    setLoading(false);
};
const getOwner = (id, returnData) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.OWNERS + id);
    if (res.success) {
        dispatch(hideLoaderAction());
        if (res.data) {
            if (returnData) {
                returnData(res.data);
            }
        }
    }
    dispatch(hideLoaderAction());
};
const editOwner = (id, data, setLoading, history) => async (dispatch) => {
    setLoading(true);
    const payload = {
        ...data,
    };

    const res = await api("put", Constants.END_POINT.OWNERS + id, payload);
    if (res.success) {
        history.goBack();
        dispatch(showToast({ severity: "success", summary: res.message }));
    }
    setLoading(false);
};
const deleteOwner = (id) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("delete", Constants.END_POINT.OWNERS + id);
    if (res.success) {
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

export { getOwner, editOwner, deleteOwner, addOwners };
