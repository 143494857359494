import React, { useEffect } from "react";
import { CustomDropDown, CustomForm, CustomInput } from "../../shared/Input/AllInputs";
import CompanyContainer from "./CompanyContainer";
import PrimaryButton from "../../shared/Button/PrimaryButton";
import InputLayout from "../../shared/Input/InputLayout";
import CustomMultipleFieldsAdd from "./CustomMultipleFieldsAdd";
import CustomMultipleFieldsAddOwner from "./CustomMultipleFieldsAddOwner";

const CompanyForm = ({ type }) => {
    const { data, handleChange, loading, onSubmit, setFormType, allCountry, State, City, onCancel, clubDelete, ownerDelete, id } = CompanyContainer();
    useEffect(() => {
        setFormType(type);
    }, [type, setFormType]);

    return (
        <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
            <InputLayout label="Main Contact Details" col={12} extraClassName="surface-700 text-white-alpha-90 font-medium py-3 text-lg" />
            <CustomInput label="First Name" name="firstName" data={data} onChange={handleChange} />
            <CustomInput label="Last Name" name="lastName" data={data} onChange={handleChange} />
            <CustomInput label="Email Address" name="email" data={data} disabled={type === "EDIT" ? true : false} onChange={handleChange} />
            <CustomInput label="Mobile Number" name="mobilePhone" data={data} keyfilter="num" onChange={handleChange} />
            <InputLayout label="Company Details" col={12} extraClassName="surface-700 text-white-alpha-90 font-medium py-3 text-lg" />
            <CustomInput label="Tax ID" name="taxId" value={data?.taxId} data={data} onChange={handleChange} />
            <CustomInput label="Company Name" name="companyName" value={data?.companyName} data={data} onChange={handleChange} />
            <CustomInput label="Company Phone" name="phone" value={data?.phone} data={data} keyfilter="num" onChange={handleChange} />
            <CustomInput label="Company Email" name="primaryEmail" value={data?.primaryEmail} data={data} onChange={handleChange} />
            <CustomInput label="Company Alternate Email" name="alternateEmail" value={data?.alternateEmail} data={data} onChange={handleChange} />
            <CustomInput label="Company Address" name="address1" value={data?.address1} data={data} onChange={handleChange} />
            <CustomDropDown
                label="Company Country"
                name="country"
                disabled={true}
                options={allCountry}
                value={data?.country}
                data={data}
                onChange={handleChange}
            />
            <CustomDropDown
                label="Company State"
                name="state"
                options={State?.getStatesOfCountry(data?.country)?.map((item) => {
                    return { name: item.name, value: item.isoCode };
                })}
                value={data?.state}
                data={data}
                onChange={handleChange}
            />
            <CustomDropDown
                label="Company City"
                name="city"
                options={City?.getCitiesOfState(data?.country, data?.state)?.map((item) => {
                    return { name: item.name, value: item.name };
                })}
                value={data?.city}
                data={data}
                onChange={handleChange}
            />
            <CustomInput label="Company Zip Code" name="zipCode" value={data?.zipCode} data={data} onChange={handleChange} />
            {type === "EDIT" || type === "VIEW" ? (
                <>
                    <InputLayout label="Clubs" col={12} extraClassName="surface-700 text-white-alpha-90 font-medium py-3 text-lg" />
                    <CustomMultipleFieldsAdd clubsData={data?.clubs} clubDelete={clubDelete} id={id} type={type} />
                    <InputLayout label="Owners" col={12} extraClassName="surface-700 text-white-alpha-90 font-medium py-3 text-lg" />
                    <CustomMultipleFieldsAddOwner ownersData={data?.owners} ownerDelete={ownerDelete} id={id} type={type} />
                </>
            ) : null}

            <InputLayout label="Subscription" col={12} extraClassName="surface-700 text-white-alpha-90 font-medium py-3 text-lg" />
            <CustomInput label="Subscription" name="subscription" value={data?.subscription} onChange={handleChange} />
            <div className="col-12 flex justify-content-end align-items-center">
                {type !== "VIEW" && (
                    <>
                        <PrimaryButton label="Save" loading={loading} onClick={onSubmit} col={6} style={{ width: "max-content" }} />
                        <PrimaryButton label="Cancel" onClick={onCancel} col={6} style={{ width: "max-content" }} />
                    </>
                )}
            </div>
        </CustomForm>
    );
};

export default CompanyForm;
