import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteCompany, getCompanies } from "../../redux/actions/companyAction";
import { confirmPopup } from "primereact/confirmpopup";

export default function Index() {
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCompanies(setLoading));
    }, [dispatch]);

    let { AllCompany } = useSelector((state) => state.company);

    const [loading, setLoading] = useState(false);

    const [search, setSearch] = useState("");
    const getFilteredData = (data, search) => {
        if (search.length) {
            data = data.filter(
                (item) => item?.name?.includes(search.toLowerCase()) || item?.phone?.includes(search) || item?.email?.includes(search.toLowerCase())
            );
        }
        return data;
    };

    AllCompany = getFilteredData(AllCompany, search);
    let inActiveCustomers = AllCompany?.filter((item) => item.isDeleted);
    inActiveCustomers = getFilteredData(inActiveCustomers, search);

    const onDelete = (e, id) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete this user? This action will permanently delete the user account. This action cannot be undone.",
            icon: "pi pi-exclamation-triangle",
            accept: () => dispatch(deleteCompany(id)),
            reject: () => {},
        });
    };

    return {
        AllCompany,
        inActiveCustomers,
        loading,
        history,
        search,
        setSearch,
        onDelete,
    };
}
