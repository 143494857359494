import Dashboard from "../views/Dashboard/Dashboard";
import Company from "../views/Company/Company";
import Profile from "../views/Profile";
import AddCompany from "../views/Company/AddCompany";
import EditCompany from "../views/Company/EditCompany";
import ViewCompany from "../views/Company/ViewCompany";
import AddClubs from "../views/Company/clubs/AddClubs";
import EditClubs from "../views/Company/clubs/EditClubs";
import AddOwners from "../views/Company/owners/AddOwners";
import EditOwners from "../views/Company/owners/EditOwners";

export const AppRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        exact: true,
    },
    {
        path: "/company",
        name: "Company",
        component: Company,
        exact: true,
    },
    {
        path: "/company/add",
        name: " Add Company",
        component: AddCompany,
    },
    {
        path: "/company/edit/:id",
        name: " Edit Company",
        component: EditCompany,
    },
    {
        path: "/company/view/:id",
        name: " View Company",
        component: ViewCompany,
    },
    {
        path: "/clubs/add/:companyId",
        name: " Add Clubs",
        component: AddClubs,
    },
    {
        path: "/clubs/edit/:id",
        name: " Edit Clubs",
        component: EditClubs,
    },
    {
        path: "/owners/add/:companyId",
        name: " Add Owners",
        component: AddOwners,
    },
    {
        path: "/owners/edit/:id",
        name: " Edit Owners",
        component: EditOwners,
    },
    {
        path: "/settings",
        name: "Settings",
        component: Profile,
        exact: true,
    },
];
