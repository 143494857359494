import React, { useEffect } from "react";
import ClubsContainer from "./ClubsContainer";
import PrimaryButton from "../../../shared/Button/PrimaryButton";
import { CustomDropDown, CustomForm, CustomInput } from "../../../shared/Input/AllInputs";

const ClubsForm = ({ type }) => {
    const { allCountry, State, City, handleChangeClubAdd, clubAddData, setFormType, onSubmit, loading } = ClubsContainer();
    useEffect(() => {
        setFormType(type);
    }, [type, setFormType]);
    return (
        <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
            <CustomInput label="Name" name="name" value={clubAddData?.name} data={clubAddData} onChange={handleChangeClubAdd} />
            <CustomInput label="Club Email" name="email" value={clubAddData?.email} data={clubAddData} onChange={handleChangeClubAdd} />
            <CustomInput
                label="Club Phone"
                name="phoneNumber"
                value={clubAddData?.phoneNumber}
                data={clubAddData}
                keyfilter="num"
                onChange={handleChangeClubAdd}
            />
            <CustomInput label="Club Address" name="address" value={clubAddData?.address} data={clubAddData} onChange={handleChangeClubAdd} />
            <CustomDropDown
                label="Club Country"
                name="country"
                options={allCountry}
                value={clubAddData?.country}
                disabled={true}
                data={clubAddData}
                onChange={handleChangeClubAdd}
            />
            <CustomDropDown
                label="Club State"
                name="state"
                options={State.getStatesOfCountry(clubAddData?.country)?.map((item) => {
                    return { name: item.name, value: item.isoCode };
                })}
                value={clubAddData?.state}
                data={clubAddData}
                onChange={handleChangeClubAdd}
            />
            <CustomDropDown
                label="Club City"
                name="city"
                options={City.getCitiesOfState(clubAddData?.country, clubAddData?.state)?.map((item) => {
                    return { name: item.name, value: item.name };
                })}
                value={clubAddData?.city}
                data={clubAddData}
                onChange={handleChangeClubAdd}
            />
            <CustomInput label="Club Zip Code" data={clubAddData} name="zipCode" value={clubAddData?.zipCode} onChange={handleChangeClubAdd} />
            <div className="col-2 p-1 flex justify-content-end align-items-center">
                <PrimaryButton label="Save" loading={loading} onClick={onSubmit} style={{ width: "max-content" }} />
            </div>
        </CustomForm>
    );
};

export default ClubsForm;
