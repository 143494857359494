import React, { useEffect, useState } from "react";
import { Country, State, City } from "country-state-city";
import { allValidations } from "../../../utils/formValidations";
import { showFormErrors } from "../../../utils/commonFunctions";
import { showToast } from "../../../redux/actions/toastAction";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { addClubs, editClub, getClub } from "../../../redux/actions/clubsAction";

const ClubsContainer = () => {
    const dispatch = useDispatch();
    const { id, companyId } = useParams();
    const history = useHistory();

    const allCountry = Country.getAllCountries().map((item) => {
        return { name: item?.name, value: item?.isoCode };
    });

    const [clubAddData, setClubAddData] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        address: "",
        country: "US",
        state: "",
        city: "",
        zipCode: "",
    });

    const [required, setRequired] = useState(["email", "phoneNumber", "address", "country", "state", "city", "zipCode"]);
    const [initialState, setInitialState] = useState({});
    const [formType, setFormType] = useState("EDIT");
    const [loading, setLoading] = useState(false);

    const handleChangeClubAdd = ({ name, value }) => {
        let formErrors = allValidations(name, value, clubAddData, required);
        setClubAddData((prev) => {
            return {
                ...prev,
                [name]: value,
                formErrors,
            };
        });
    };

    const onSubmit = () => {
        if (showFormErrors(clubAddData, setClubAddData, required, initialState)) {
            if (formType === "EDIT") {
                dispatch(editClub(id, clubAddData, setLoading, history));
            } else {
                dispatch(addClubs(companyId, clubAddData, setLoading, history));
            }
        } else {
            dispatch(
                showToast({
                    severity: "error",
                    summary: "Please Fill All Required Fields",
                })
            );
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    };

    useEffect(() => {
        if (id) {
            dispatch(
                getClub(id, (data) => {
                    setClubAddData({
                        name: data?.name,
                        company: data?.company?._id,
                        email: data?.email,
                        phoneNumber: data?.phoneNumber,
                        address: data?.address,
                        country: data?.country,
                        state: data?.state,
                        city: data?.city,
                        zipCode: data?.zipCode,
                    });
                })
            );
        }
        setInitialState(clubAddData);
    }, []);

    return {
        allCountry,
        State,
        City,
        clubAddData,
        handleChangeClubAdd,
        setFormType,
        onSubmit,
        loading,
    };
};

export default ClubsContainer;
