import React from "react";
import CustomCard from "../../shared/Card/CustomCard";
import CompanyForm from "./CompanyForm";

export default function AddCompany({ name }) {
    return (
        <CustomCard title={name}>
            <CompanyForm type="ADD" />
        </CustomCard>
    );
}
