import React from "react";
import PrimaryButton from "../../shared/Button/PrimaryButton";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Country, State } from "country-state-city";

const CustomMultipleFieldsAdd = ({ clubsData, clubDelete, id, type }) => {
    const history = useHistory();
    const actions = (d) => {
        return (
            <div className="flex justify-content-around">
                <Button
                    icon="pi pi-pencil"
                    text
                    onClick={() => history.push({ pathname: `/clubs/edit/${d._id}` })}
                    severity="danger"
                    aria-label="Edit"
                />
                <Button icon="pi pi-trash" text onClick={(e) => clubDelete(e, d._id)} severity="danger" aria-label="Cancel" />
            </div>
        );
    };

    const countryTemplate = (rowData) => {
        return <div>{Country?.getCountryByCode(rowData?.country)?.name}</div>;
    };
    const stateTemplate = (rowData) => {
        return <div>{State?.getStateByCodeAndCountry(rowData?.state, rowData?.country)?.name}</div>;
    };
    return (
        <>
            {type !== "VIEW" ? (
                <div className="col-2 p-1 flex justify-content-end align-items-center w-full">
                    <PrimaryButton label="Add" onClick={() => history.push({ pathname: `/clubs/add/${id}` })} style={{ width: "max-content" }} />
                </div>
            ) : null}

            <div className="col-12">
                <DataTable paginator value={clubsData} selectionMode="checkbox" showGridlines filterDisplay="menu" rows={4} emptyMessage={true}>
                    <Column field="_id" header="Id"></Column>
                    <Column field="email" header="Email"></Column>
                    <Column field="phoneNumber" header="Phone"></Column>
                    <Column field="address" header="Address"></Column>
                    <Column field="country" header="Country" body={countryTemplate}></Column>
                    <Column field="state" header="State" body={stateTemplate}></Column>
                    <Column field="city" header="City"></Column>
                    <Column field="zipCode" header="Zipcode"></Column>
                    {type !== "VIEW" ? <Column body={actions} style={{ width: "30px" }} header="Action"></Column> : null}
                </DataTable>
            </div>
        </>
    );
};

export default CustomMultipleFieldsAdd;
