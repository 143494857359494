import { types } from "../types/types";
const intitalState = {
    AllCompany: [],
};

const companyReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.ALL_COMPANY:
            return {
                ...state,
                AllCompany: action.payload,
            };
        default:
            return { ...state };
    }
};

export default companyReducer;
