import React, { useState } from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";

export default function CompanyTable({
    heading,
    AllCompany,
    loading,
    history,
    search,
    setSearch,
    onDelete,
    linkTo,
    buttonTitle,
    extraClassName,
    selectionMode,
}) {
    const [selectedRow, setSelectedRow] = useState([]);
    const actions = (d) => {
        return (
            <div className="flex justify-content-start">
                <i className="pi pi-eye cursor-pointer" onClick={() => history.push(`company/view/${d._id}`)}></i>
                <i className="pi pi-pencil cursor-pointer ml-3 mr-3" onClick={() => history.push(`company/edit/${d._id}`)}></i>
                <i className="pi pi-trash cursor-pointer" onClick={(e) => onDelete(e, d._id)}></i>
            </div>
        );
    };

    const filters = {
        time: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        subscriptionExpireOn: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    };

    return (
        <>
            <TableCard
                title={heading}
                onSearch={setSearch}
                searchKeyword={search}
                linkTo={linkTo}
                buttonTitle={buttonTitle}
                extraClassName={extraClassName}
            >
                <DataTable
                    paginator
                    value={AllCompany}
                    selection={selectedRow}
                    onSelectionChange={(e) => setSelectedRow(e.value)}
                    selectionMode={selectionMode ? selectionMode : null}
                    showGridlines
                    filter={filters}
                    filterDisplay="menu"
                    rows={10}
                    loading={loading}
                    emptyMessage="No company found."
                >
                    <Column body={(row) => row.firstName + " " + row.lastName} header="Name" style={{ width: "250px" }}></Column>
                    <Column field="companyName" header="Company Name" style={{ width: "250px" }}></Column>
                    <Column field="email" header="Email" style={{ width: "250px" }}></Column>
                    <Column field="mobilePhone" header="Phone" style={{ width: "250px" }}></Column>
                    <Column body={actions} style={{ width: "80px" }} header="Action"></Column>
                </DataTable>
            </TableCard>
        </>
    );
}
