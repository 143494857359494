import React from "react";
import OwnersForm from "./OwnersForm";
import CustomCard from "../../../shared/Card/CustomCard";

const AddOwners = ({ name }) => {
    return (
        <CustomCard title={name}>
            <OwnersForm type="ADD" />
        </CustomCard>
    );
};

export default AddOwners;
