import React from "react";
import InputLayout from "../../shared/Input/InputLayout";
import { CustomInput } from "../../shared/Input/AllInputs";
import { CustomPhoneNumber } from "../../shared/Input/CustomPhoneInput";
import PrimaryButton from "../../shared/Button/PrimaryButton";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CustomMultipleFieldsAddOwner = ({ ownersData, ownerDelete, id, type }) => {
    const history = useHistory();
    const actions = (d, rowIndex) => {
        return (
            <div className="flex justify-content-around">
                <Button
                    icon="pi pi-pencil"
                    text
                    onClick={() => history.push({ pathname: `/owners/edit/${d._id}`, state: id })}
                    severity="danger"
                    aria-label="Edit"
                />
                <Button icon="pi pi-trash" text onClick={(e) => ownerDelete(e, d._id)} severity="danger" aria-label="Cancel" />
            </div>
        );
    };
    return (
        <>
            {type !== "VIEW" ? (
                <div className="col-2 p-1 flex justify-content-end align-items-center w-full">
                    <PrimaryButton label="Add" onClick={() => history.push({ pathname: `/owners/add/${id}` })} style={{ width: "max-content" }} />
                </div>
            ) : null}

            <div className="col-12">
                <DataTable paginator value={ownersData} selectionMode="checkbox" showGridlines filterDisplay="menu" rows={4} emptyMessage={true}>
                    <Column field="name" header="Name"></Column>
                    <Column field="email" header="Email"></Column>
                    <Column field="phone" header="Phone"></Column>
                    <Column field="ownedPercentage" header="Owned Percentage"></Column>
                    {type !== "VIEW" ? <Column body={actions} style={{ width: "10px" }} header="Action"></Column> : null}
                </DataTable>
            </div>
            <div className="mb-3 mt-2"></div>
        </>
    );
};

export default CustomMultipleFieldsAddOwner;
