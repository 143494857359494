const menuItems = () => {
    return [
        {
            items: [
                {
                    icon: "pi pi-home",
                    label: "Home",
                    to: "/dashboard",
                },
                {
                    icon: "pi pi-users",
                    label: "Companies",
                    to: "/company",
                },
                // {
                //     icon: "pi pi-cog",
                //     label: "Settings",
                //     to: "/settings",
                // },
            ],
        },
    ];
};

export { menuItems };
