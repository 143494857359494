import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";

const getCompanies = (setLoading) => async (dispatch) => {
    if (setLoading) {
        setLoading(true);
    }
    const res = await api("get", Constants.END_POINT.COMPANY);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.ALL_COMPANY,
                payload: res.data,
            });
        }
    }
    if (setLoading) {
        setLoading(false);
    }
};

const addCompany = (data, setLoading, history) => async (dispatch) => {
    setLoading(true);
    const payload = {
        ...data,
    };

    const res = await api("post", Constants.END_POINT.COMPANY, payload);
    if (res.success) {
        dispatch(getCompanies(null));
        history.push(`/company/edit/${res?.data?.companyDetails?._id}`);
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    setLoading(false);
};

const getCompany = (id, returnData) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.COMPANY + id);
    if (res.success) {
        dispatch(hideLoaderAction());
        if (res.data) {
            if (returnData) {
                returnData(res.data);
            }
        }
    }
    dispatch(hideLoaderAction());
};
const editCompany = (id, data, setLoading, history) => async (dispatch) => {
    setLoading(true);

    const payload = {
        ...data,
    };

    const res = await api("put", Constants.END_POINT.COMPANY + id, payload);
    if (res.success) {
        dispatch(getCompanies(null));
        history.push("/company");
        dispatch(showToast({ severity: "success", summary: res.message }));
    }
    setLoading(false);
};

const deleteCompany = (id) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("delete", Constants.END_POINT.COMPANY + id);
    if (res.success) {
        dispatch(getCompanies(null));
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

export { getCompanies, getCompany, editCompany, deleteCompany, addCompany };
