import React, { useEffect } from "react";
import OwnersContainer from "./OwnersContainer";
import PrimaryButton from "../../../shared/Button/PrimaryButton";
import { CustomForm, CustomInput } from "../../../shared/Input/AllInputs";

const OwnersForm = ({ type }) => {
    const { ownerAddData, handleChangeOwnerAdd, setFormType, onSubmit, loading } = OwnersContainer();
    useEffect(() => {
        setFormType(type);
    }, [type, setFormType]);
    return (
        <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
            <CustomInput label="Name" name="name" value={ownerAddData?.name} data={ownerAddData} onChange={handleChangeOwnerAdd} />
            <CustomInput label="Email" name="email" value={ownerAddData?.email} data={ownerAddData} onChange={handleChangeOwnerAdd} />
            <CustomInput label="Phone" name="phone" value={ownerAddData?.phone} keyfilter="num" data={ownerAddData} onChange={handleChangeOwnerAdd} />
            <CustomInput
                label="Owned Percentage"
                name="ownedPercentage"
                value={ownerAddData?.ownedPercentage}
                keyfilter="num"
                data={ownerAddData}
                onChange={handleChangeOwnerAdd}
            />
            <div className="col-2 p-1 flex justify-content-end align-items-center">
                <PrimaryButton label="Save" loading={loading} onClick={onSubmit} style={{ width: "max-content" }} />
            </div>
        </CustomForm>
    );
};

export default OwnersForm;
