import React from "react";
import Lottie from "lottie-react";
import animation from "../assets/gif/notFound.json";
import CustomCard from "../shared/Card/CustomCard";

export default function NotFound({ name }) {
  return (
    <CustomCard title="Page Not Found" backable>
      <div className="col-4 mx-auto">
        <Lottie animationData={animation} loop={true} />
      </div>
    </CustomCard>
  );
}
