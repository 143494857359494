import React from "react";
import CustomCard from "../../../shared/Card/CustomCard";
import OwnersForm from "./OwnersForm";

const EditOwners = ({ name }) => {
    return (
        <CustomCard title={name}>
            <OwnersForm type="EDIT" />
        </CustomCard>
    );
};

export default EditOwners;
