import React, { useState, useEffect } from "react";
import {
  changePassword,
  getProfile,
  updateProfile,
} from "../redux/actions/profileAction";
import { useSelector, useDispatch } from "react-redux";
import {
  CustomForm,
  CustomInput,
  CustomPassword,
} from "../shared/Input/AllInputs";
import CustomCard from "../shared/Card/CustomCard";
import PrimaryButton from "../shared/Button/PrimaryButton";
import { allValidations } from "../utils/formValidations";
import CustomImageInput from "../shared/Input/CustomImageInput";
import { showFormErrors } from "../utils/commonFunctions";
const Profile = ({ name }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  const profile = useSelector((state) => state?.profile?.userProfile);

  const [data, setData] = useState({
    name: "",
    email: "",
    image: "",
    password: "",
    newPassword: "",
  });

  const [data1, setData1] = useState({
    password: "",
    newPassword: "",
  });

  useEffect(() => {
    setData({
      name: profile?.name,
      email: profile?.email,
      image: profile.image ? [profile.image] : [],
    });
  }, [profile]);

  const handleChange = ({ name, value }) => {
    const formErrors = allValidations(name, value, data);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const handleChange1 = ({ name, value }) => {
    const formErrors = allValidations(name, value, data1);
    setData1((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (showFormErrors(data, setData)) {
      dispatch(updateProfile(data));
    }
  };

  const handlePassword = (e) => {
    e.preventDefault();
    if (showFormErrors(data1, setData1)) {
      dispatch(changePassword(data1));
    }
  };
  return (
    <CustomCard title={name} backable={false}>
      <CustomForm>
        <CustomImageInput
          name="image"
          onFilesChange={handleChange}
          data={data}
          editable={true}
        />
        <CustomInput
          data={data}
          onChange={handleChange}
          name="name"
          label="Name"
          required
        />
        <CustomInput
          data={data}
          onChange={handleChange}
          name="email"
          label="Email"
          required
          disabled
        />
        <PrimaryButton label="Save Changes" onClick={handleSubmit} />
      </CustomForm>
      <CustomForm>
        <CustomPassword
          data={data1}
          onChange={handleChange1}
          name="password"
          label="Old Password"
          required
        />
        <CustomPassword
          data={data1}
          onChange={handleChange1}
          name="newPassword"
          label="New Password"
          required
        />
        <PrimaryButton label="Change Password" onClick={handlePassword} />
      </CustomForm>
    </CustomCard>
  );
};

export default Profile;
