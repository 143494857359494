import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";
import { addCompany, editCompany, getCompany } from "../../redux/actions/companyAction";
import { Country, State, City } from "country-state-city";
import { showToast } from "../../redux/actions/toastAction";
import { deleteClub } from "../../redux/actions/clubsAction";
import { deleteOwner } from "../../redux/actions/ownersAction";
import { confirmPopup } from "primereact/confirmpopup";

export default function CompanyContainer() {
    const dispatch = useDispatch();
    const history = useHistory();

    const allCountry = Country.getAllCountries().map((item) => {
        return { name: item?.name, value: item?.isoCode };
    });

    const { id } = useParams();

    const [data, setData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        mobilePhone: "",
        taxId: "",
        companyName: "",
        phone: "",
        primaryEmail: "",
        alternateEmail: "",
        address1: "",
        country: "US",
        state: "",
        city: "",
        zipCode: "",
        clubs: [],
        owners: [],
    });
    const [required, setRequired] = useState([
        "firstName",
        "lastName",
        "email",
        "mobilePhone",
        "taxId",
        "companyName",
        "phone",
        "primaryEmail",
        "alternateEmail",
        "address1",
        "country",
        "state",
        "city",
        "zipCode",
        // "clubsData",
        // "ownersData",
    ]);
    const [initialState, setInitialState] = useState({});

    useEffect(() => {
        if (id) {
            dispatch(
                getCompany(id, (data) => {
                    setData({
                        firstName: data?.firstName,
                        lastName: data?.lastName,
                        email: data?.email,
                        mobilePhone: data?.mobilePhone,
                        taxId: data?.taxId,
                        companyName: data?.companyName,
                        phone: data?.phone,
                        primaryEmail: data?.primaryEmail,
                        alternateEmail: data?.alternateEmail,
                        address1: data?.address1,
                        country: data?.country,
                        state: data?.state,
                        city: data?.city,
                        zipCode: data?.zipCode,

                        clubs: data?.clubs,
                        owners: data?.owners,
                    });
                })
            );
        }
        setInitialState(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, dispatch]);

    const [formType, setFormType] = useState("EDIT");
    const [loading, setLoading] = useState(false);

    const handleChange = ({ name, value }) => {
        if (formType !== "VIEW") {
            const formErrors = allValidations(name, value, data, required, initialState);
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    const onSubmit = () => {
        if (showFormErrors(data, setData, required, initialState)) {
            if (formType === "EDIT") {
                dispatch(editCompany(id, data, setLoading, history));
            } else {
                dispatch(addCompany(data, setLoading, history));
            }
        } else {
            dispatch(
                showToast({
                    severity: "error",
                    summary: "Please Fill All Required Fields",
                })
            );
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    };

    const onCancel = () => {
        setData({
            name: "",
            email: "",
            mobileNumber: "",
            taxId: "",
            companyName: "",
            phone: "",
            primaryEmail: "",
            alternateEmail: "",
            address1: "",
            country: "US",
            state: "",
            city: "",
            zipCode: "",
            // clubsData: [],
            // ownersData: [],
        });
        history.push("/company");
    };

    const clubDelete = (e, id) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete this user? This action will permanently delete the user account. This action cannot be undone.",
            icon: "pi pi-exclamation-triangle",
            accept: () => dispatch(deleteClub(id)),
            reject: () => {},
        });
    };

    const ownerDelete = (e, id) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete this user? This action will permanently delete the user account. This action cannot be undone.",
            icon: "pi pi-exclamation-triangle",
            accept: () => dispatch(deleteOwner(id)),
            reject: () => {},
        });
    };

    return {
        data,
        handleChange,
        loading,
        onSubmit,
        setFormType,
        allCountry,
        State,
        City,
        onCancel,
        clubDelete,
        ownerDelete,
        id,
    };
}
