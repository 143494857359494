import React, { useState } from "react";

export default function Dashboard({ name, history }) {
    const [usersCount] = useState({
        activeUser: 0,
        inActiveUser: 0,
    });
    return (
        <div className="grid">
            <div className="col-12 lg:col-6 xl:col-3 cursor-pointer" onClick={() => history.push("/customers")}>
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Active Users</span>
                            <div className="text-900 font-medium text-xl">{usersCount?.activeUser}</div>
                        </div>
                        <div
                            className="flex align-items-center justify-content-center bg-cyan-100 border-round"
                            style={{ width: "2.5rem", height: "2.5rem" }}
                        >
                            <i className="pi pi-users text-cyan-500 text-xl" />
                        </div>
                    </div>
                    <span className="text-500">Total Active User</span>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3 cursor-pointer" onClick={() => history.push("/inactive-customers")}>
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Inactive Users</span>
                            <div className="text-900 font-medium text-xl">{usersCount?.inActiveUser}</div>
                        </div>
                        <div
                            className="flex align-items-center justify-content-center bg-red-100 border-round"
                            style={{ width: "2.5rem", height: "2.5rem" }}
                        >
                            <i className="pi pi-user-minus text-red-500 text-xl" />
                        </div>
                    </div>
                    <span className="text-500">Total InActive User</span>
                </div>
            </div>
        </div>
    );
}
