import { equal, length } from "./javascript";
import { firstLetterToUppercase, emailValidation, number, passwordValidation, otpCodeValidation } from "./regex";
import zipcodes from "zipcodes";

export const allValidations = (name, value, data, required = [], initialData) => {
    let formErrors = { ...data.formErrors };
    switch (name) {
        case "companyEmail":
        case "companyAlternateEmail":
        case "email":
            if (name != "formErrors" && required.includes(name)) {
                if (equal(length(value))) {
                    formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
                } else if (!emailValidation(value)) {
                    formErrors[name] = `Please enter valid email!`;
                } else {
                    formErrors[name] = "";
                }

                break;
            } else {
                break;
            }

        case "password":
            if (name != "formErrors" && required.includes(name)) {
                if (equal(length(value))) {
                    formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
                } else if (!passwordValidation(value)) {
                    formErrors[
                        name
                    ] = `Please enter a password with 8-16 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character`;
                } else {
                    formErrors[name] = "";
                }
                break;
            } else {
                break;
            }
        case "zipCode":
        case "zipcode":
            if (name != "formErrors" && required.includes(name)) {
                if (equal(length(value))) {
                    formErrors[name] = `Zip Code is required!`;
                } else if (zipcodes?.lookup(value)?.state != data?.state) {
                    formErrors[name] = `Please enter a valid Zip Code`;
                } else {
                    formErrors[name] = "";
                }
                break;
            } else {
                break;
            }

        case "companyZipcode":
            if (name != "formErrors" && required.includes(name)) {
                if (equal(length(value))) {
                    formErrors[name] = `Company Zip Code is required!`;
                } else if (zipcodes?.lookup(value)?.state != data?.companyState) {
                    formErrors[name] = `Please enter a valid Zip Code`;
                } else {
                    formErrors[name] = "";
                }
                break;
            } else {
                break;
            }

        case name:
            if (name != "formErrors" && required?.includes(name)) {
                if (
                    typeof value === "object" &&
                    value != null &&
                    !Array.isArray(value) &&
                    typeof initialData[name] == typeof value &&
                    initialData[name] !== null
                ) {
                    if (required.includes(`${name}|`)) {
                        Object.keys(value).map((item) => {
                            if (required.includes(`${name}|${item}`)) {
                                if (equal(length(value[item])) || value[item] == null) {
                                    formErrors[name] = {
                                        ...formErrors[name],
                                        [item]: `${firstLetterToUppercase(item)} is required!`,
                                    };
                                } else {
                                    formErrors[name] = {
                                        ...formErrors[name],
                                        [item]: "",
                                    };
                                }
                            }
                        });
                    } else {
                        Object.keys(value).map((item) => {
                            if (equal(length(value[item])) || value[item] == null) {
                                formErrors[name] = {
                                    ...formErrors[name],
                                    [item]: `${firstLetterToUppercase(item)} is required!`,
                                };
                            } else {
                                formErrors[name] = {
                                    ...formErrors[name],
                                    [item]: "",
                                };
                            }
                        });
                    }
                } else {
                    if (equal(length(value)) || value == null) {
                        formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
                    } else {
                        formErrors[name] = "";
                    }
                }

                break;
            } else {
                break;
            }

        default:
            break;
    }
    // console.log("lastformerror",formErrors)
    return formErrors;
};
