import React from "react";
import CompanyTable from "./CompanyTable";
import Index from "./index";

const Company = () => {
    const { AllCompany, loading, history, search, setSearch, onDelete } = Index();
    return (
        <CompanyTable
            heading="All Companies"
            AllCompany={AllCompany}
            loading={loading}
            history={history}
            search={search}
            setSearch={setSearch}
            onDelete={onDelete}
            linkTo="/company/add"
            buttonTitle="Add Company"
            extraClassName="font-normal"
        />
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};
export default React.memo(Company, comparisonFn);
