import React, { useEffect, useState } from "react";
import { allValidations } from "../../../utils/formValidations";
import { showFormErrors } from "../../../utils/commonFunctions";
import { showToast } from "../../../redux/actions/toastAction";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { addOwners, editOwner, getOwner } from "../../../redux/actions/ownersAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const OwnersContainer = () => {
    const dispatch = useDispatch();
    const { id, companyId } = useParams();
    const history = useHistory();
    const [ownerAddData, setOwnerAddData] = useState({
        name: "",
        email: "",
        phone: "",
        ownedPercentage: "",
    });

    const [required, setRequired] = useState(["name", "email", "phone", "ownedPercentage"]);
    const [initialState, setInitialState] = useState({});
    const [formType, setFormType] = useState("EDIT");
    const [loading, setLoading] = useState(false);

    const handleChangeOwnerAdd = ({ name, value }) => {
        let formErrors = allValidations(name, value, ownerAddData, required);
        setOwnerAddData((prev) => {
            return {
                ...prev,
                [name]: value,
                formErrors,
            };
        });
    };

    const onSubmit = () => {
        if (showFormErrors(ownerAddData, setOwnerAddData, required, initialState)) {
            if (formType === "EDIT") {
                dispatch(editOwner(id, ownerAddData, setLoading, history));
            } else {
                dispatch(addOwners(companyId, ownerAddData, setLoading, history));
            }
        } else {
            dispatch(
                showToast({
                    severity: "error",
                    summary: "Please Fill All Required Fields",
                })
            );
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    };

    useEffect(() => {
        if (id) {
            dispatch(
                getOwner(id, (data) => {
                    setOwnerAddData({
                        company: data?.company?._id,
                        name: data?.name,
                        email: data?.email,
                        phone: data?.phone,
                        ownedPercentage: data?.ownedPercentage,
                    });
                })
            );
        }
        setInitialState(ownerAddData);
    }, []);

    return {
        ownerAddData,
        handleChangeOwnerAdd,
        setFormType,
        onSubmit,
        loading,
    };
};

export default OwnersContainer;
