import api from "../../services/api";
import Constants from "../../services/constant";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";
import { types } from "../types/types";
import { uploadImages } from "../../utils/uploadFiles";

export const getProfile = () => async (dispatch) => {
  const res = await api("get", Constants.END_POINT.PROFILE);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.USER_DETAIL,
        payload: res.data,
      });
    }
  } else {
    dispatch(
      showToast({ severity: "warn", summary: "Error", detail: res.message })
    );
  }
};

export const updateProfile = (data) => async (dispatch) => {
  dispatch(showLoaderAction());
  let image = await uploadImages(data.image);
  const payload = {
    ...data,
    image: image[0],
  };
  const res = await api("put", Constants.END_POINT.UPDATE_PROFILE, payload);
  if (res.success) {
    dispatch(getProfile());
    dispatch(
      showToast({
        severity: "success",
        summary: "Success",
        detail: res.message,
      })
    );
  } else {
    dispatch(
      showToast({ severity: "warn", summary: "Error", detail: res.message })
    );
  }
  dispatch(hideLoaderAction());
};
export const changePassword = (data) => async (dispatch) => {
  dispatch(showLoaderAction());
  const payload = {
    ...data,
  };
  const res = await api("post", Constants.END_POINT.CHANGE_PASSWORD, payload);
  if (res.success) {
    dispatch(getProfile());
    dispatch(
      showToast({
        severity: "success",
        summary: "Success",
        detail: res.message,
      })
    );
  } else {
    dispatch(
      showToast({ severity: "warn", summary: "Error", detail: res.message })
    );
  }
  dispatch(hideLoaderAction());
};
