export const types = {
    SHOW_TOAST: "SHOW_TOAST",
    SHOW_LOADER: "SHOW_LOADER",
    HIDE_LOADER: "HIDE_LOADER",
    SHOW_SIDEBAR_LOADER: "SHOW_SIDEBAR_LOADER",
    HIDE_SIDEBAR_LOADER: "HIDE_SIDEBAR_LOADER",
    USER_DETAIL: "USER_DETAIL",

    ALL_COMPANY: "ALL_COMPANY",

    CHANGE_LOCATIONS: "CHANGE_LOCATIONS",
    CHANGE_LOCATION_NAME: "CHANGE_LOCATION_NAME",
    CHANGE_EMPLOYEES: "CHANGE_EMPLOYEES",
    CHANGE_LOCATIONS_MENU: "CHANGE_LOCATIONS_MENU",
    CHANGE_PRODUCTS: "CHANGE_PRODUCTS",
    CHANGE_INGREDIENTS: "CHANGE_INGREDIENTS",
    CHANGE_ADDON: "CHANGE_ADDON",
    CHANGE_ORDERS_MENU: "CHANGE_ORDERS_MENU",
    CHANGE_TRACKS: "CHANGE_TRACKS",
    CHANGE_PROGRAMS: "CHANGE_PROGRAMS",
    UPDATE_UPLOAD_PROGRESS: "UPDATE_UPLOAD_PROGRESS",
    CHANGE_PLANS: "CHANGE_PLANS",
};
