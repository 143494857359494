import React from "react";
import CustomCard from "../../shared/Card/CustomCard";
import CompanyForm from "./CompanyForm";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function ViewCompany({ name }) {
    const { id } = useParams();
    return (
        <CustomCard
            title={name}
            linkTo={`/company/edit/${id}`}
            // buttonTitle="Edit"
            extraClassName="font-normal"
        >
            <CompanyForm type="VIEW" />
        </CustomCard>
    );
}
